import { Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/system';
import { useIsMobile } from '@fingo/lib/hooks';

const listRenderer = (headers, data, spacing) => (
  data.map((profile) => (
    <Stack
      key={profile.id}
      spacing={spacing}
      sx={{ mb: 2, '&:last-child': { mb: 0 } }}
    >
      {headers.map((header) => (
        <Typography sx={{ py: 0.5, whiteSpace: 'pre-line' }} variant="body2" key={`${header.label}: ${header.getValue(profile)}`}>
          <Typography component="span" variant="h6">{header.label}</Typography>
          {' '}{header.getValue(profile)}
        </Typography>
      ))}
    </Stack>
  )));

const ProfileTable = ({ headersList, data }) => {
  const isMobile = useIsMobile();
  const headers = isMobile ? [[].concat(...headersList)] : headersList;
  return (
    <Stack
      direction={isMobile ? 'column' : 'row'}
      justifyContent={isMobile ? 'normal' : 'space-evenly'}
      flex={1}
      sx={{ mb: '40px' }}
    >
      {headers.map((header) => (listRenderer(header, data, 2)))}
    </Stack>
  );
};

ProfileTable.propTypes = {
  headersList: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    getValue: PropTypes.func,
  }))).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

export default ProfileTable;
