import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import BaseBarGraph from './BaseBarGraph';

const GraphByDay = ({ queryValue, accumulated, ...baseBarProps }) => {
  const [orderedData, setOrderedData] = useState([]);
  const beforeDate = moment().subtract(1, 'months').format('MMMM');
  const afterDate = moment().format('MMMM');
  useEffect(() => {
    if (!queryValue) return;
    setOrderedData(() => {
      const finalOrder = [];
      let beforeAccumulated = 0;
      let afterAccumulated = 0;
      const maxDay = moment().date();
      [...Array(31).keys()].forEach((value) => {
        const day = value + 1;
        const beforeMonth = moment().subtract(1, 'months').month();
        const afterMonth = moment().month();
        const beforeFlow = queryValue.find(
          (flow) => moment(flow.dateIssued).date() === day
          && moment(flow.dateIssued).month() === beforeMonth,
        );
        const actualFlow = queryValue.find(
          (flow) => moment(flow.dateIssued).date() === day
          && moment(flow.dateIssued).month() === afterMonth,
        );
        const beforeMoney = beforeFlow ? beforeFlow.money : accumulated * beforeAccumulated;
        const afterMoney = actualFlow ? actualFlow.money : accumulated * afterAccumulated;
        finalOrder.push({
          name: String(day),
          [beforeDate]: beforeMoney,
          [afterDate]: (day > maxDay) ? 0 : afterMoney,
        });
        beforeAccumulated = beforeMoney || 0;
        afterAccumulated = afterMoney || 0;
      });
      return finalOrder;
    });
  }, [queryValue, accumulated, afterDate, beforeDate]);
  return (
    <BaseBarGraph
      orderedData={orderedData}
      beforeName={beforeDate}
      actualName={afterDate}
      {...baseBarProps}
    />
  );
};

GraphByDay.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  queryValue: PropTypes.array,
  accumulated: PropTypes.bool.isRequired,
};

GraphByDay.defaultProps = {
  queryValue: [],
};

export default GraphByDay;
