import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import BaseBarGraph from './BaseBarGraph';

const GraphByMonth = ({ queryValue, accumulated, ...baseBarProps }) => {
  const [orderedData, setOrderedData] = useState([]);
  const beforeDate = moment().subtract(1, 'years').format('YYYY');
  const afterDate = moment().format('YYYY');
  useEffect(() => {
    setOrderedData(() => {
      const finalOrder = [];
      let beforeAccumulated = 0;
      let afterAccumulated = 0;
      const maxMonth = moment().month() + 1;
      [...Array(12).keys()].forEach((value) => {
        const month = value + 1;
        const beforeYear = moment().subtract(1, 'years').year();
        const afterYear = moment().year();
        const beforeFlow = queryValue.find(
          (flow) => flow.year === beforeYear
          && flow.month === month,
        );
        const actualFlow = queryValue.find(
          (flow) => flow.year === afterYear
            && flow.month === month,
        );
        const beforeMoney = beforeFlow ? beforeFlow.money : accumulated * beforeAccumulated;
        const afterMoney = actualFlow ? actualFlow.money : accumulated * afterAccumulated;
        finalOrder.push({
          name: String(moment().month(month - 1).format('MMM')),
          [beforeDate]: beforeMoney,
          [afterDate]: maxMonth < month ? 0 : afterMoney,
        });
        beforeAccumulated = beforeMoney;
        afterAccumulated = afterMoney;
      });
      return finalOrder;
    });
  }, [queryValue, accumulated, afterDate, beforeDate]);
  return (
    <BaseBarGraph
      orderedData={orderedData}
      beforeName={beforeDate}
      actualName={afterDate}
      {...baseBarProps}
    />
  );
};

GraphByMonth.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  queryValue: PropTypes.array,
  accumulated: PropTypes.bool.isRequired,
};

GraphByMonth.defaultProps = {
  queryValue: [],
};

export default GraphByMonth;
